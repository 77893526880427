import React from 'react'
import { Slider } from './shadcn-ui/slider';

const SliderStep = ({labels, text, value, setValue, expiryText, max}) => {

  return (
    <div className="p-2 border rounded-lg mt-2 mb-3">
        <div className="w-full font-SuisseIntlLight flex justify-between text-sm text-[#B2B2B5]">
          {labels.map((label, index) => (
            <span key={index}>{label}</span>
          ))}
        </div>
      <div className="relative">
        <Slider
          className="mt-2"
          inviteSlider
          min={0}
          max={max}
          step={1}
          value={[value]}
          onValueChange={(val) => setValue(val[0])}
        />
      </div>
      <div className="flex justify-between mt-4 text-sm text-[#A3A3AC]">
        <span className='font-SuisseIntlLight font-semibold text-black'>{text}</span>
        <span className="font-medium">{expiryText}</span>
      </div>
    </div>
  )
}

export default SliderStep