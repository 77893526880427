import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/shadcn-ui/table";
import { Input } from "@/components/shadcn-ui/input";
import { Button } from "@/components/shadcn-ui/button";
import deleteIcon from "@/images/SVG/deleteIcon.svg"
const Vocabulary = () => {
    const vocab = [
      {
        id: 1,
        value1: "Pediatory",
        value2: "Kinderheilkunde",
        icon: deleteIcon,
      },
      {
        id: 2,
        value1: "Pediatory",
        value2: "Kinderheilkunde",
        icon: deleteIcon,
      },
      {
        id: 3,
        value1: "Pediatory",
        value2: "Kinderheilkunde",
        icon: deleteIcon,
      },
    ];
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0 flex flex-row justify-between items-center">
          <div>
            <CardTitle className="font-semibold">Vocabulary</CardTitle>
            <CardDescription className="font-medium mt-2 text-[14px] text-[#71717A]">
              Mark the words that will be added to the reports in a different way
            </CardDescription>
          </div>
          <div className="md:block hidden">
            <Button>Add Word</Button>
          </div>
        </CardHeader>
        <CardContent className="md:p-6 px-0">
          <Card className="w-full rounded-2xl">
            <Table className="md:block hidden">
              <TableHeader className="p-3 md:p-0 w-full">
                <TableRow className="w-full">
                  <TableCell className="font-SuisseIntlLight font-semibold text-[#71717A]">
                    Find
                  </TableCell>
                  <TableCell className="font-SuisseIntlLight font-semibold text-[#71717A]">
                    Replace
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {vocab.map((vocab) => (
                  <TableRow key={vocab.id}>
                    <TableCell>
                      <Input
                        type="text"
                        value={vocab.value1}
                        className="w-full font-semibold"
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-3 items-center">
                        <Input
                          type="text"
                          value={vocab.value2}
                          className="w-full font-semibold"
                        />
                        <Button variant="outline" className="flex-none">
                          <img src={vocab.icon} alt="Delete icon" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <CardContent className="md:hidden p-2 w-full">
              <Button className="w-full mb-2">Add Word</Button>
              <Table className="border-t border-[#e4e4e7]">
                <TableBody>
                  {vocab.map((vocab) => (
                    <TableRow key={vocab.id}>
                      <TableCell className="p-2">
                        <Input
                          type="text"
                          value={vocab.value1}
                          className="w-full font-semibold rounded-b-none"
                        />
                        <Input
                          type="text"
                          value={vocab.value2}
                          className="w-full font-semibold rounded-t-none"
                        />
                      </TableCell>
                      <TableCell className="p-2 text-center align-top w-16">
                        <Button variant="outline" className="flex-none">
                          <img src={vocab.icon} alt="Delete icon" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    );
};

export default Vocabulary;