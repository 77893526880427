import React, { useEffect } from 'react';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFoundPage from './pages/NotFound';
import MainPage from './pages/MainPage';
import Reports from './pages/Reports';
import Report from './pages/Report';
import useWebSocket from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBackendReports } from './redux/ReportsApis';
import { UpdateReport, setLatestPayload } from './redux/ReportsSlice';
import { pageSize } from './utils/constants';
import { SubscriptionProvider } from './utils/SubscriptionContext';
import { setPayingCustomer, getPayingCustomer } from './redux/UserSlice';
import Layout from './Layout';
import { setNavigate } from './utils/authSession';
import Home from './pages/Home';
import ForgotPass from './pages/ForgotPass';
import SignUp from './pages/SignUp';
import VerifyPass from './pages/VerifyPass';
import MeetingAi from './pages/MeetingAi';
import ConsultAi from './pages/ConsultAi';
import Profile from './pages/Profile';
import { SidebarProvider } from './components/shadcn-ui/sidebar';
import { useCurrentTool } from './utils/ToolHelpers';

const WS_URL = process.env.REACT_APP_WS_URL

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('user_id') || "";
  const { currentToolFields } = useCurrentTool();

  const payingCustomer = useSelector(getPayingCustomer);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  useEffect(() => {
    // Clear the previous paying customer state
    dispatch(setPayingCustomer(null));

    // Simulate fetching the paying customer status
    const fetchPayingCustomerStatus = async () => {
      
      dispatch(setPayingCustomer(payingCustomer));
    };

    if (userId) {
      fetchPayingCustomerStatus();
    }
  }, 
  [dispatch, userId, payingCustomer]);
  console.log(userId)

  const { lastJsonMessage } = useWebSocket(
    `${WS_URL}?userId=${userId}`,
    {
      onOpen: () => console.log('WebSocket connection established.'),
      onClose: () => console.log('WebSocket connection closed.'),
      onMessage: (event) => console.log('Event: ', event),
      shouldReconnect: () => true,
      heartbeat: {
        message: 'ping',
        returnMessage: 'pong',
        timeout: 5 * 60000,
        interval: 5 * 1000,
      },
    }
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const payload = lastJsonMessage.data;
      console.log("lastJsonMessage: ", lastJsonMessage);
      dispatch(setLatestPayload(payload));

      if (payload.status === "generated") {
        dispatch(UpdateReport(payload));
        if (!!userId) {
          dispatch(FetchBackendReports({ userId, status: 'generated', page: 1, pageSize, currentToolFields }));
        }
      } else if (payload.status === "processing") {
        dispatch(UpdateReport(payload));
        if (!!userId) {
          dispatch(FetchBackendReports({ userId, status: 'processing', page: 1, pageSize, currentToolFields }));
        }
      } else if (payload.status === "completed" && !payload.report_fetched) {
        if (payload.error === "no_transcription_found") {
          toast.error(`Report# ${payload.row_number}: Transcription failed. Please try a different recording.`, { toastId: `toast-${payload.id}` });
        } else {
          toast.error(`Report# ${payload.row_number}: The report could not be created. Please try again.`, { toastId: `toast-${payload.id}` });
        }
        dispatch(UpdateReport(payload));
      }
    }
  }, [dispatch, userId, lastJsonMessage, currentToolFields]);

  return (
    <SubscriptionProvider>
      <SidebarProvider>
      <Layout>
        <Routes>
        <Route path='/' element={userId ? <Home/> : <MainPage/>} />
          <Route path='/meeting' element={<MeetingAi />} />
          <Route path='/scribe' element={<Reports />} />
          <Route path='/consult' element={<ConsultAi />} />
          <Route path='/scribe/:id' element={<Report />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route path='/settings' element={<Profile/>}/>
          <Route path='/reset-password' element={userId ? <NotFoundPage/> :<ForgotPass/>}/>
          <Route path='/sign-up' element={userId ? <NotFoundPage /> : <SignUp/>}/>
          <Route path='/verify-password' element={userId ? <NotFoundPage/> : <VerifyPass/>}/>
        </Routes>
      </Layout>
      </SidebarProvider>
    </SubscriptionProvider>
  );
};

export default App;
