import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/shadcn-ui/table";
import { GradientBorder } from "@/components/ui/GradientBorder";
import { Trash2 } from "lucide-react";
import linkIcon from "@/images/SVG/link.svg";
import InviteMembers from "./InviteMembers";


const Members = () => {
    const [isInviteOpen, setInviteOpen] = useState(false);
  
    const members = [
      { id: 1, fullname: "Lukas Wagner", role: "Admin" },
      { id: 2, fullname: "Sophia Lee", role: "Admin" },
      { id: 3, fullname: "Max Smith", role: "Member" },
      { id: 4, fullname: "Emma Johnson", role: "Member" },
      { id: 5, fullname: "Oliver Davis", role: "Member" },
      { id: 6, fullname: "Ava Martinez", role: "Member" },
      { id: 7, fullname: "Ethan Garcia", role: "Member" },
    ];
  
    const handleInviteClick = () => {
      setInviteOpen(true);
    };
  
    const closeInviteComponent = () => {
      setInviteOpen(false);
    };
  
    return (
      <div>
        {!isInviteOpen ? (
          <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
            <CardHeader className="pt-0 md:px-6 px-0 flex-row flex justify-between items-center">
              <CardTitle>Member</CardTitle>
              <button
                onClick={handleInviteClick}
                className="underline font-SuisseIntlLight font-semibold text-sm"
              >
                Invite Members
              </button>
            </CardHeader>
            <CardContent className="md:px-6 px-0 p-0">
              {members.length > 0 ? (
                <Card className="rounded-2xl">
                  <CardContent className="px-0 p-0">
                    <Table>
                      <TableHeader>
                        <TableRow className="hover:bg-transparent">
                          <TableCell className="font-semibold text-[#71717A] pr-0">
                            ID
                          </TableCell>
                          <TableCell className="font-semibold text-[#71717A]">
                            Full Name
                          </TableCell>
                          <TableCell className="font-semibold text-[#71717A]">
                            Role
                          </TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {members.map((member) => (
                          <TableRow key={member.id}>
                            <TableCell className="text-[#71717A]">
                              {member.id}
                            </TableCell>
                            <TableCell>{member.fullname}</TableCell>
                            <TableCell>{member.role}</TableCell>
                            <TableCell className="align-end pr-0">
                              <Trash2
                                className="text-[#71717a] text-sm"
                                width={18}
                                height={18}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              ) : (
                <Card className="rounded-2xl">
                  <CardContent className="p-3 h-96 flex justify-center items-center flex-col">
                    <GradientBorder>
                      <div className="shadow-sm bg-white rounded-full px-4 py-4">
                        <img width={"24px"} src={linkIcon} alt="recording mic" />
                      </div>
                    </GradientBorder>
                    <h1 className="font-semibold font-SuisseIntlLight mt-[16px] md:text-[24px] text-[20px] text-center">
                      You don't have any members
                    </h1>
                    <p className="mt-[4px] text-[#505050] md:text-[16px] text-[14px] font-SuisseIntlLight font-normal">
                      Invite one
                    </p>
                    <button
                      onClick={handleInviteClick}
                      className="underline font-SuisseIntlLight font-semibold text-sm"
                    >
                      Invite Members
                    </button>
                  </CardContent>
                </Card>
              )}
            </CardContent>
          </Card>
        ) : (
          <InviteMembers members={members} onClose={closeInviteComponent} />
        )}
      </div>
    );
};

export default Members;
