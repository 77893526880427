import React, { useState } from 'react'
import DarkLogo from '../images/logotype.png'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardDescription, CardFooter, CardTitle } from '@/components/shadcn-ui/card'
import { GradientBorder } from '@/components/ui/GradientBorder';
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { Button } from '@/components/shadcn-ui/button'
import resetIcon from '../images/SVG/resrtPass.svg'
import { resetPassword } from 'aws-amplify/auth'
import { toast } from 'react-toastify'

const ForgotPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (!email) {
      toast.error('Please enter your email.');
      return;
    }
    setLoading(true);
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        toast.success('Verification code sent to your email.');
        navigate('/verify-password', { state: { email } });
      } else if (output.nextStep.resetPasswordStep === 'DONE') {
        console.log('Password reset successfully');
      }
    } catch (err) {
      switch(err.message) {
        case 'Username/client id combination not found.':
          toast.error('Email not found. Please enter a valid email.');
          break;
        default:
          toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex gap-3 items-center fixed top-12">
        <img className='md:w-36 w-28 object-contain' src={DarkLogo} alt="logo not found" />
        <p className='text-black font-SuisseIntlLight font-medium text-[14px] md:text-[16px]'>Medical AI Suite</p>
      </div>
      <div className="flex justify-center items-center h-screen">
        <div className='w-full bg-[#FAFAFA] flex flex-col items-center font-SuisseIntl mt-12 text-3xl font-bold text-gray-400'>
          <Card className="mb-auto w-[90vw] md:w-[440px] max-w-[440px] rounded-[20px]">
            <div className='flex justify-center mt-7 mb-4'>
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} height={'24px'} src={resetIcon} alt='Login circle not found' />
                </div>
              </GradientBorder>
            </div>
            <CardTitle className="text-[20px] text-center w-full font-semibold">Reset Password</CardTitle>
            <CardDescription className="text-[13.67px] text-[#09090B] text-center w-full font-medium">Enter the email with which you've registered.</CardDescription>
            <CardContent className="mt-6">
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="email" className="font-semibold">Email Address</Label>
                  <Input
                    className="text-[#868C98] font-normal"
                    id="email"
                    placeholder="Please enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-center w-full flex-col">
              <Button
                variant="default"
                className="w-full"
                onClick={handleResetPassword}
                disabled={loading}
              >
                Reset Password
              </Button>
              <p className='text-[14px] font-normal mt-4 text-[#09090B]'>Changed your mind? <span onClick={() => navigate(-1)} className='text-black font-semibold underline cursor-pointer'>Go Back</span></p>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;