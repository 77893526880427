import React, { useEffect, useRef,useCallback, useState } from 'react';
import UpoloadFile from '../images/upload.png';
import CheckCircle from '../images/check-circle.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from './ui/Button'
import { setFileUploading, setSelectedReport } from '../redux/ReportsSlice';
import { GradientBorder } from './ui/GradientBorder';
import GeneratingIcon from '../images/generating.gif'
import Loader from './Loader';
import { toast } from 'react-toastify'
import { createRecord, handleUpdateStatus, handleUpdateError, getSignedUrl, uploadToS3 } from '../utils/HelperFunctions';
import {CardWrapper} from './ui/CardWrapper';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import RecordForm from './RecordForm';
import { Tooltip } from 'react-tooltip';
import succeedIcon from '../images/SVG/succeedIcon.svg'
import { useCurrentTool } from '@/utils/ToolHelpers';

const UploadFile = ({isDisabled, customTemplate, languages, allModelsLanguageList,setHidingtabs }) => {
  
  const { currentToolFields, generation_name } = useCurrentTool();

  const [fieldValues, setFieldValues] = useState(() => {
    const initialValues = currentToolFields.reduce((acc, field) => {
      acc[field.name] = localStorage.getItem(field.name) || ''; 
      return acc;
    }, {});
    return initialValues;
  });

  const payload = useSelector((state) => state?.reportsSlice?.latestPayload)
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [AudioDuration, setAudioDuration] = useState(0)
  const [uploadModelOpen, setUploadModelOpen] = useState(false)
  const [generateReport, setGenerateReport] = useState('')
  const [uploading, setUploading] = useState(true)
  const [tempReport, setTempReport] = useState({})
  const [resetState, setResetState] = useState()
  const reportIdRef = useRef(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleResetState = useCallback(() => {
    setFieldValues(
      currentToolFields.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {})
    );

    setSelectedFile(null);
    setAudioDuration(0);
    setUploadModelOpen(false);
    setGenerateReport('');
    setUploading(true);
    setTempReport({});
    setResetState(prevState => !prevState); 
    reportIdRef.current = null
  }, [currentToolFields]);

  useEffect(() => {
    const theFile = document.getElementById('fileInput');
    const initialize = () => {
      document.body.onfocus = checkIt;
    };
    const checkIt = () => {
      if (theFile.value.length === 0) {
        setUploadModelOpen(false);
      }
      document.body.onfocus = null;
    };
    theFile?.addEventListener('click', initialize);
    return () => {
      theFile?.removeEventListener('click', initialize);
    };
  }, [resetState]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setUploadModelOpen(false)
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio?.addEventListener('loadedmetadata', () => {
      setAudioDuration(audio.duration);
    });
  };

  const genertae=()=>{
    setSelectedFile("");
  }
  
  useEffect(() => {
    const theFile = document.getElementById('fileInput');
    const initialize = () => {
      document.body.onfocus = checkIt;
    };
    const checkIt = () => {
      if (theFile.value.length === 0) {
        setUploadModelOpen(false)
      }
      document.body.onfocus = null;
    };
    theFile?.addEventListener('click', initialize);
    return () => {
      theFile?.removeEventListener('click', initialize);
    };
  }, []);

  // const formatTime = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   const remainingSeconds = Math.floor(seconds % 60);
  //   const timeString = `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  //   return timeString.toLocaleString('en-US');
  // };


  const handleDrop = (event) => {
    if (disabled) return;
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.addEventListener('loadedmetadata', () => {
      setAudioDuration(audio.duration);
    });
  };
  useEffect(()=>(
    setHidingtabs(AudioDuration)
  ),[AudioDuration, setHidingtabs])


  const handleGoToReport = () => {
    navigate('/report')
  }

  const handleDragOver = (event) => {
    if (disabled) return;
    event.preventDefault();
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  // const handleGenerateNewReport = (e) => {
  //   e.preventDefault()
  //   handleGenerateReport({ patientName: name, doctorName: doctor, clinicName: clinic, selectedFile: selectedFile, audioLanguage, reportLanguage: reportLanguage, comment, template: template })
  //   setGenerateReport('generating')
  // }

  useEffect(() => {
    if (!!payload) {
      if (reportIdRef.current && payload.id === reportIdRef?.current) {
        if (payload.report_fetched) {
          setTempReport(payload)
        } else if (payload.status === "completed") {
          handleResetState()
        }
      }
    }
  }, [payload, handleResetState])

  const handleGenerateReport = async (data) => {
    const { toolFieldsData, reportLanguage, audioLanguage, comment, template, uses_custom_template } = data;
    const file = data.selectedFile || data.recordedAudioBlob;
  
    console.log('File to upload:', file); // Add this log
  
    if (!file) {
      toast.error('No file selected for upload.');
      return;
    }
  
    let documentId;
    try {
      dispatch(setFileUploading(true));
  
      const userId = localStorage.getItem('user_id');
  
      const allowedAudioTypes = [
        'audio/flac',
        'audio/mp4',
        'video/mp4',
        'audio/mpeg',
        'audio/ogg',
        'audio/wav',
        'video/webm',
        'audio/webm',
        'audio/x-m4a',
        'audio/x-wav',
      ];

      if (file.size / (1024 * 1024) > 90.0) {
        toast.error('The audio file is too large. The maximum size is 90 MB.');
        return;
      }
      if (!allowedAudioTypes.includes(file.type)) {
        toast.error('Please upload an audio file.');
        return;
      }
  
      toolFieldsData.forEach((field) => {
        if(field.name !== 'patient_name')
          localStorage.setItem(field.name, field.value)
      })
      localStorage.setItem('audio_language', audioLanguage);
      localStorage.setItem('report_language', reportLanguage);
  
      documentId = await createRecord({
        userId,
        toolFieldsData,
        reportLanguage,
        audioLanguage,
        comment,
        template,
        meta_type: file.type,
        uses_custom_template,
      });
      console.log('Record response ', documentId);
      if (!documentId) {
        toast.error('Failed to create record. Please try again!');
        return;
      }
      setUploading(true);
      const signedUrl = await getSignedUrl({ id: documentId, name: `${documentId}.mp3`, type: 'upload' });
      const uploadStatus = await uploadToS3({ id: documentId, signedUrl, file });
      if (!uploadStatus) {
        await handleUpdateError({ error: 'file not uploaded', id: documentId });
        return toast.error('Error uploading the file. Please try again.');
      }
      const recordUpdated = await handleUpdateStatus({ status: 'processing', documentId });
      console.log('Record status update ', recordUpdated);
      if (!recordUpdated) {
        return;
      }
      const dataToSend = {
        id: documentId,
        user_id: userId,
      };
  
      const accessToken = await fetchAuthSessionWithLogout();
      if (!accessToken) {
        return
      }
      const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/report`, {
        method: 'POST',
        body: JSON.stringify(dataToSend),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken.toString()}`,
        },
      });
      const responseData = await response.json();
      console.log('responseData: ', responseData);
      if (responseData.statusCode !== 200) {
        if (response.status === 401) {
          handleUpdateError({ id: documentId, error: 'Session expired.' });
          localStorage.removeItem('user_session');
          navigate('/');
          toast.error('Your session has expired. Please login and try again.');
          return;
        } else {
          handleResetState();
          handleUpdateError({ id: documentId, reportError: JSON.stringify(responseData) });
          toast.error(`The ${generation_name.toLowerCase()} could not be created. Please try again.`);
          return;
        }
      }
      reportIdRef.current = documentId;
      setUploading(false);
      dispatch(setFileUploading(false));
    } catch (error) {
      console.log('API error ', error);
      if (JSON.stringify(error) === '{}' || !error) {
        handleUpdateError({ error: 'Failed to make request to backend server', documentId });
      } else {
        handleUpdateError({ error: JSON.stringify(error), documentId });
      }
      handleResetState();
  
      toast.error(`The ${generation_name.toLowerCase()} could not be created. Please try again.`);
    }
  };


  useEffect(() => {
    if (tempReport?.report?.length > 0 && generateReport === 'generating') {
      setGenerateReport('')
      
      let newReport = { ...tempReport };

      currentToolFields.forEach((field) => {
        const placeholder = `placeholder_${field.name}`;
        const value = fieldValues[field.name] || '';
        newReport.report = newReport.report?.replace(new RegExp(placeholder, 'g'), value);
      });

      setGenerateReport('upload-report-generated')
      dispatch(setSelectedReport(newReport))
    }
  }, [tempReport, dispatch, generateReport, currentToolFields, fieldValues])

  const disabled = isDisabled.loading || isDisabled.noWords;

  return (
    <div>
      {
        !selectedFile && generateReport === '' && (
          <>
            {isDisabled.noWords && <Tooltip id="no-words" place="bottom"/>}
            <CardWrapper className='border-none'>
              <div
                className={`flex flex-col items-center justify-center w-full border border-[#E4E4E7] p-5 rounded-xl ${disabled && 'cursor-not-allowed'}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={!disabled ? handleBrowseClick : undefined}
                data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'
              >
                <GradientBorder>
                  <div className="shadow-sm bg-white rounded-full px-4 py-4">
                    {uploadModelOpen ? (
                      <img width={'24px'} src={GeneratingIcon} alt='recording mic ' loading='eager'  />
                    ):(
                      <img width={'24px'} src={UpoloadFile} alt='recording mic ' />
                    )}
                  </div>
                </GradientBorder>
                {uploadModelOpen ? (
                  <>
                    <h1 className='text-[24px] sm:w-[320px] max-w-[320px] font-medium mt-[16px]'>Selecting</h1>
                    <p className='mt-[4px] sm:w-80 font-SuisseIntlLight font-normal text-[16px] text-[#505050]'>Please wait few seconds</p>
                  </>
                ) : (
                  <>
                    <h1 className='text-[24px] font-medium mt-[16px]'>Choose a file or drag & drop it here</h1>
                      <p className='mt-[4px] sm:w-[320px] max-w-[320px] text-[16px] font-SuisseIntlLight font-normal text-[#505050]'>MP3, MP4 and MOV formats, up to 50 MB.</p>
                  </>
                )}
                <input
                  type="file"
                  onClick={() => { setUploadModelOpen(true) }}
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                  id="fileInput"
                  accept="audio/*, video/mp4, video/webm"
                />
                <label htmlFor="fileInput">
                  <Button className={`mt-[16px] ${disabled && 'cursor-not-allowed opacity-80'}`} >
                  Browse files
                  </Button>
                </label>
              </div>
            </CardWrapper>
          </>
        )
      }
      {
        selectedFile && generateReport === '' && (
          <CardWrapper className='border-none'>
            {/* <div style={{ borderRadius: '20px' }} className='border border-[#E2E4E9] flex flex-col justify-center items-center p-[22px] sm:p-[48px]'> */}
            <div className='flex flex-col justify-center items-center w-full'>
            <GradientBorder >
              <div className="shadow-sm bg-white rounded-full p-3">
                <img className='max-w-none' src={succeedIcon} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-bold mt-[16px] font-SuisseIntlLight text-[24px]'>Speech Recorded</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] mb-[12px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>Fill data below and generate report.</p>
              <RecordForm GenerateReport={setGenerateReport} HandleResetState={handleResetState} UploadNewRecording={genertae} customTemplates={customTemplate} language={languages} allModelsLanguageList={allModelsLanguageList} handleGenerateReport={handleGenerateReport} recordedAudioBlob={selectedFile} />
                </div>
          </CardWrapper>
        )
      }

      {
        generateReport === 'generating' && (
          <CardWrapper className='border-none'>
          <div className='flex flex-col justify-center items-center'>
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <Loader />
                </div>
              </GradientBorder>
              {
                uploading ? (
                  <>
                    <h1 className='font-medium mt-[16px] text-[24px]'>Uploading...</h1>
                    <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-normal text-[16px]'>Please wait for a few seconds.</p>
                  </>
                ) : (
                  <>
                    <h1 className='font-medium mt-[16px] text-[24px]'>Generating...</h1>
                      <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-normal text-[16px]'>Please wait for a few minutes.</p>
                  </>
                )
              }
              <div>
                {uploading ? (
                  <Button variant='disabled' className='mt-[16px] cursor-default hidden' onClick={()=>{}}>
                    Add {generation_name.toLowerCase()}
                </Button>
                ) : (
                  <Button variant='light' className='mt-[16px]' onClick={handleResetState}>
                    Add {generation_name.toLowerCase()}
                  </Button>
                )}
              </div>
            </div>
          </CardWrapper>
        )
      }
      {
        generateReport === 'upload-report-generated' && (
          <CardWrapper>
          <div className='flex flex-col justify-center items-center'>
              <GradientBorder variant={2}>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} src={CheckCircle} alt='recording mic ' />
                </div>
              </GradientBorder>
              <h1 className='font-medium mt-[16px] text-[24px]'>The {generation_name.toLowerCase()} has been generated</h1>
              <p className='sm:w-[320px] max-w-[320px] mt-[4px] text-[#505050] font-SuisseIntlLight font-normal text-[16px]'>You can review it by clicking the button below</p>
              <div>
                <Button variant='light' className='mt-[16px]' onClick={handleGoToReport}>
                  Go to {generation_name.toLowerCase()}
                </Button>
              </div>
            </div>
          </CardWrapper>
        )
      }
    </div >
  );
};

export default UploadFile;
