import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/shadcn-ui/table";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Button } from "@/components/shadcn-ui/button";
import { Textarea } from "@/components/shadcn-ui/textarea";
import deleteIcon from "@/images/SVG/deleteIcon.svg";

const TextModules = () => {
    const modules = [
      {
        id: 1,
        label1: "Find",
        icon: deleteIcon,
        inputValue1: "MedGuide101",
        label2: "Replace",
        inputValue2:
          "Take one tablet daily with water before meals. Avoid alcohol. Consult if symptoms persist.",
        hasMargin: true,
      },
      {
        id: 2,
        label1: "Find",
        icon: deleteIcon,
        inputValue1: "MedGuide101",
        label2: "Replace",
        inputValue2:
          "Take one tablet daily with water before meals. Avoid alcohol. Consult if symptoms persist.",
        hasMargin: false,
      },
    ];
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0 flex flex-row justify-between items-center">
          <div>
            <CardTitle className="font-SuisseIntlLight font-semibold">
              Text Modules
            </CardTitle>
            <CardDescription className="font-medium mt-2 text-[14px] text-[#71717A]">
              Mark the words that are converted to full text in the report
            </CardDescription>
          </div>
          <div className="hidden md:block">
            <Button className="font-semibold">Add Text Module</Button>
          </div>
        </CardHeader>
        <CardContent className="md:block hidden">
          <Card className="rounded-2xl">
            {modules.map((mode) => (
              <CardContent
                key={mode.id}
                className={`${mode.hasMargin === true ? "mt-3" : ""}`}
              >
                <Label className="font-semibold">{mode.label1}</Label>
                <div className="flex justify-between gap-3 my-2 items-center">
                  <Input value={mode.inputValue1} className="font-semibold" />
                  <Button variant="outline">
                    <img src={mode.icon} alt="delete" />
                  </Button>
                </div>
                <Label className="font-semibold ">{mode.label2}</Label>
                <Textarea
                  placeholder="Type your message here."
                  className="resize-none lg:h-[170px] h-[220px] md:h-[120px] mt-2 font-semibold"
                  value={mode.inputValue2}
                />
              </CardContent>
            ))}
          </Card>
        </CardContent>
        <CardContent className="md:hidden p-0">
          <Card className="rounded-2xl">
            <CardContent className="p-2">
              <Button className="font-semibold w-full mb-2">
                Add Text Module
              </Button>
              <Table>
                <TableBody>
                  {modules.map((mode) => (
                    <TableRow
                      key={mode.id}
                      className={`${mode.hasMargin === true ? "mt-3" : ""}`}
                    >
                      <TableCell>
                        <div>
                          <div className="flex items-center rounded-b-none gap-1 h-10 w-full rounded-md border font-SuisseIntlLight border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-medium file:text-[#71717a] placeholder:text-[#71717a] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#71717a] focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50">
                            <Label className="font-semibold text-[#71717A]">
                              {mode.label1}:
                            </Label>
                            <Input
                              value={mode.inputValue1}
                              className="font-semibold p-0 border-none w-fit h-fit"
                            />
                          </div>
                          <div className="flex flex-col gap-1 rounded-t-none min-h-[80px] w-full rounded-md font-SuisseIntlLight border border-slate-200 bg-white px-3 py-2 text-base ring-offset-white placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300">
                          <Label className="font-semibold text-[#71717A]">{mode.label2}:</Label>
                          <Textarea
                            placeholder="Type your message here."
                            className="resize-none h-[112px] p-0 border-none"
                            value={mode.inputValue2}
                          />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className='align-top'>
                        <Button variant="outline">
                          <img src={mode.icon} alt="delete" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    );
};

export default TextModules;
  