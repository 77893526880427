import React, { useState } from 'react'
import DarkLogo from '../images/logotype.png'
import { Card, CardContent, CardDescription, CardFooter, CardTitle } from '@/components/shadcn-ui/card'
import { GradientBorder } from '@/components/ui/GradientBorder'
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { Button } from '@/components/shadcn-ui/button'
import VerifyPassword from '../images/SVG/verifyPass.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'

const VerifyPass = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email || '';
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirmResetPassword = async () => {
    setLoading(true);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      });
      toast.success('Password reset successfully.');
      navigate('/');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE')
        toast.success('Verification code sent to your email.');
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <>
      <div className="flex gap-3 items-center fixed top-12">
        <img className='md:w-36 w-28 object-contain' src={DarkLogo} alt="logo not found" />
        <p className='text-black font-SuisseIntlLight font-medium text-[14px] md:text-[16px]'>Medical AI Suite</p>
      </div>
      <div className='flex items-center justify-center h-screen'>
        <div className='w-full bg-[#FAFAFA] flex flex-col items-center font-SuisseIntl mt-24 text-3xl font-bold text-gray-400'>
          <Card className="mb-auto w-[90vw] md:w-[440px] max-w-[440px] rounded-[20px]">
            <div className='flex justify-center mt-7 mb-4'>
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} height={'24px'} src={VerifyPassword} alt='Login circle not found' />
                </div>
              </GradientBorder>
            </div>
            <CardTitle className="text-[20px] text-center w-full font-semibold">Enter Verification Code</CardTitle>
            <CardDescription className="text-[13.67px] text-[#09090B] text-center w-full font-medium">We’ve sent a code to {email}</CardDescription>
            <CardContent className="mt-6 pb-0">
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="verify-code" className="font-semibold">Verification Code</Label>
                  <Input
                    className="text-[#868C98] font-normal"
                    id="verify-code"
                    placeholder="Please enter your verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="password" className="font-semibold">New Password</Label>
                  <Input
                    required
                    id="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Please enter your new password"
                    type="password"
                    autoComplete="new-password"
                    className="text-[#868C98] font-normal"
                  />
                </div>
              </div>
              <p
                className="text-[14px] font-semibold underline mt-1 text-[#09090B] cursor-pointer"
                onClick={!loading && handleResendCode}
              >
                Resend Code
              </p>
            </CardContent>
            <CardFooter className="flex justify-center w-full flex-col">
              <Button
                variant="default"
                className="w-full"
                onClick={handleConfirmResetPassword}
                disabled={loading}
              >
                Confirm New Password
              </Button>
              <p className='text-[14px] font-normal mt-4 text-[#09090B]'>Changed your mind? <span onClick={() => navigate(-1)} className='text-black font-semibold underline cursor-pointer'>Go Back</span></p>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default VerifyPass;