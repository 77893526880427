import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn-ui/tabs";
import { Button } from "@/components/shadcn-ui/button";
import memberIcon from "../images/SVG/member.svg";
import GeneralIcon from "../images/SVG/generalcon.svg";
import billingIcon from "../images/SVG/billingIcon.svg";
import autoChangeIcon from "../images/SVG/autoChangeIcon.svg";
import planIcon from "../images/SVG/planIcon.svg";
import templateIcon from "../images/SVG/templateIcon.svg";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { General, Members, Billing, Vocabulary, TextModules, Plans } from "@/components/Profile/index.js"

const tabTrigger = [
  {
    title: "General",
    icon: GeneralIcon,
    value: "general",
  },
  {
    title: "Members",
    icon: memberIcon,
    value: "members",
  },
  {
    id: 3,
    title: "Billing",
    icon: billingIcon,
    value: "billing",
  },
  {
    title: "Autochange",
    icon: autoChangeIcon,
    value: "autochange",
  },
  {
    title: "Template",
    icon: templateIcon,
    value: "template",
  },
  {
    title: "Plans",
    icon: planIcon,
    value: "plans",
  },
];
const autoChangeSubmenu = [
  { id: 1, title: "Vocabulary", value: "vocabulary" },
  { id: 2, title: "Text Modules", value: "text-modules" },
];

const Profile = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const handleLogout = async () => {
    await signOut();
    localStorage.removeItem("user_id");
    navigate("/");
  };
  const toggleAutoChangeSubmenu = () => {
    setExpanded((prev) => !prev);
    // Automatically select the first submenu item when "autoChange" is clicked
    if (!expanded) {
      setActiveTab(autoChangeSubmenu[0].value); // Selects "Sub-item 1" initially
    }
  };

  useEffect(() => {
    // Close submenu when switching to a tab outside of autoChange
    if (
      activeTab !== "autoChange" &&
      !autoChangeSubmenu.some((item) => item.value === activeTab)
    ) {
      setExpanded(false);
    }
  }, [activeTab]);

  return (
    <div className="mt-4 p-0 sm:p-[28px] mb-10 w-[90vw] md:w-[80vw] md:bg-white md:border md:border-[#E4E4E7] md:shadow-[#0000000A] rounded-[20px]">
      <Card className="border-none shadow-none w-full h-[100vh] bg-transparent">
        <CardHeader className="p-0 mb-3 md:hidden">
          <CardTitle className="font-bold">Settings</CardTitle>
        </CardHeader>
        <Tabs
          className="w-full h-full"
          value={activeTab}
          onValueChange={(value) => setActiveTab(value)}
        >
          <div className="w-full h-full grid md:grid-cols-6 grid-cols-1 gap-5">
            <div className="col-span-2 md:h-fit h-[82vh] flex justify-between flex-col">
              <Card className="relative rounded-2xl">
                <TabsList
                  data-orientation="vertical"
                  className="flex flex-col overflow-y-scroll md:overflow-hidden h-full justify-start gap-3 bg-transparent dark:bg-transparent"
                >
                  {tabTrigger.map((tab, index) => (
                    <>
                      <TabsTrigger
                        key={index}
                        className="w-full justify-start data-[state=active]:bg-[#F6F8FA]"
                        value={tab.value}
                        onClick={
                          tab.value === "autochange"
                            ? toggleAutoChangeSubmenu
                            : () => setActiveTab(tab.value)
                        }
                      >
                        <img src={tab.icon} className="mr-3" alt={tab.title} />
                        <span className="font-SuisseIntlLight font-semibold">
                          {tab.title}
                        </span>
                      </TabsTrigger>

                      {/* Render submenu items below "Auto Change" */}
                      {tab.value === "autochange" && expanded && (
                        <div className="p-0 shadow-none relative z-50 top-0 -left-[30px] md:left-[-30px] flex flex-col gap-3 w-2/3 bg-white md:bg-transparent rounded-[10px]">
                          {autoChangeSubmenu.map((subItem, index) => (
                            <TabsTrigger
                              key={subItem.id}
                              className={`w-full font-semibold justify-start text-sm data-[state=active]:bg-[#F6F8FA] ${index === 0 && !activeTab ? "bg-[#F6F8FA]" : ""
                                }`}
                              value={subItem.value}
                              onClick={() => setActiveTab(subItem.value)}
                            >
                              {subItem.title}
                            </TabsTrigger>
                          ))}
                        </div>
                      )}
                    </>
                  ))}
                </TabsList>
              </Card>
              <Button
                className="mt-3 w-full relative"
                variant="outline"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
            <div className="md:col-span-4 col-span-2 h-screen md:h-fit">
              <TabsContent value="general">
                <General />
              </TabsContent>
              <TabsContent value="members">
                <Members />
              </TabsContent>
              <TabsContent value="billing">
                <Billing />
              </TabsContent>
              <TabsContent value="vocabulary">
                <Vocabulary />
              </TabsContent>
              <TabsContent value="text-modules">
                <TextModules />
              </TabsContent>
              <TabsContent value="template">
                <h1>template</h1>
              </TabsContent>
              <TabsContent value="plans">
                <Plans />
              </TabsContent>
            </div>
          </div>
        </Tabs>
      </Card>
    </div>
  );
};

export default Profile;