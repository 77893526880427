import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/shadcn-ui/dialog";
import { Button } from "@/components/shadcn-ui/button";
import AddToken from "@/components/AddToken";


const Plans = () => {
    const planning = [
      {
        id: 1,
        name: "Basic",
        description: "Good for beginners",
        pricePerWord: "0.03",
        minAmount: null,
      },
      {
        id: 2,
        name: "Standard",
        description: "Good for frequent use",
        pricePerWord: "0.02",
        minAmount: "10,000",
      },
      {
        id: 3,
        name: "Premium",
        description: "Good for professionals",
        pricePerWord: "0.01",
        minAmount: "25,000",
      },
    ];
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0">
          <CardTitle>Plans</CardTitle>
          <CardDescription className="font-medium text-[14px]">
            Choose the plan according to your needs
          </CardDescription>
        </CardHeader>
        <CardContent className="flex gap-3 flex-col md:p-6 p-0 md:pt-0 md:pb-3">
          {planning.map((plan) => (
            <Card key={plan.id} className="p-3 pt-0 md:p-0 rounded-2xl">
              <div className="flex justify-between items-center p-3 flex-wrap gap-3 md:gap-0 md:border-b md:border-b-[#E4E4E7]">
                <div>
                  <CardTitle className="text-[22px]">{plan.name}</CardTitle>
                  <CardDescription className="font-semibold text-[12px]">
                    {plan.description}
                  </CardDescription>
                </div>
                <div>
                  <Dialog className="hidden md:flex">
                    <DialogTrigger className="hidden md:flex">
                      <Button className="font-medium hidden md:flex">
                        Get Tokens
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="rounded-3xl">
                      <AddToken />
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
              <CardContent className="flex justify-between items-center md:pt-6 pt-3 md:p-6 p-3 flex-wrap gap-3 md:gap-0">
                <div className="md:w-3/6 w-[45%] border-r border-r-[#E4E4E7]">
                  <CardTitle className="text-[20px]">
                    {plan.pricePerWord}
                  </CardTitle>
                  <CardDescription className="text-[12px] font-semibold">
                    Price Per Word
                  </CardDescription>
                </div>
                <div className="md:w-3/6 md:px-5">
                  <CardTitle className="text-[20px]">
                    {plan.minAmount === null ? "No" : `${plan.minAmount} CFH`}
                  </CardTitle>
                  <CardDescription className="text-[12px] font-semibold">
                    Minimum Amount
                  </CardDescription>
                </div>
              </CardContent>
              <Dialog className="md:hidden flex w-full">
                <DialogTrigger className="md:hidden flex w-full">
                  <Button className="font-medium w-full md:hidden flex">
                    Get Tokens
                  </Button>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <AddToken />
                </DialogContent>
              </Dialog>
            </Card>
          ))}
        </CardContent>
        <CardFooter className="block px-0 md:px-6 mt-3 md:mt-0">
          <p className="text-[14px] font-SuisseIntlLight font-normal">
            Enterprise customers can{" "}
            <span className="underline font-semibold">get in touch</span> with us
            here for a tailor made deal
          </p>
        </CardFooter>
      </Card>
    );
};

export default Plans;