import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/shadcn-ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@/components/shadcn-ui/table";
import { Button } from "@/components/shadcn-ui/button";
import AddToken from "@/components/AddToken";

const Billing = () => {
    const invoices = [
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "+1000 Tokens",
        name: "Invoice",
      },
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "+750 Tokens",
        name: "Invoice",
      },
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "-780 Tokens",
        name: "Report Generated",
      },
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "-200 Tokens",
        name: "Report Generated",
      },
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "+160 Tokens",
        name: "Invoice",
      },
      {
        date: "10 Oct 2024, 18:56",
        totalTokens: "+100 Tokens",
        name: "Invoice",
      },
    ];
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0">
          <CardTitle>Billing</CardTitle>
          <CardDescription className="text-[14px] mt-2 font-normal text-[#71717a]">
            Manage your balance and keep track of your transaction history
          </CardDescription>
        </CardHeader>
        <CardContent className="md:p-6 p-0">
          <Card className="flex p-3 justify-between items-center rounded-2xl">
            <div>
              <CardDescription className="text-[14px] font-semibold text-[#71717a]">
                Token Balance
              </CardDescription>
              <CardTitle className="mt-2">1,026</CardTitle>
            </div>
            <div>
              <Dialog>
                <DialogTrigger>
                  <Button className="font-medium">Add Tokens</Button>
                </DialogTrigger>
                <DialogContent className="rounded-3xl">
                  <AddToken />
                </DialogContent>
              </Dialog>
            </div>
          </Card>
          <Card className="border-none shadow-none">
            <CardDescription className="text-black font-SuisseIntlLight font-semibold text-[14px] my-3">
              Transaction History
            </CardDescription>
            <Card className="rounded-2xl">
              <Table>
                <TableBody>
                  {invoices.map((invoice, index) => (
                    <>
                      <TableRow
                        key={index}
                        className="hidden md:flex justify-between"
                      >
                        <TableCell className="text-[12px] text-[#09090B] font-semibold">
                          {invoice.date}
                        </TableCell>
                        <TableCell className="text-[12px] text-[#09090B] font-semibold">
                          {invoice.name}
                        </TableCell>
                        <TableCell className="text-right text-[12px] text-[#09090B] font-semibold">
                          {invoice.totalTokens}
                        </TableCell>
                      </TableRow>
                      <TableRow key={index} className="md:hidden">
                        <TableCell className="text-[12px] text-[#09090B] font-semibold">
                          <div>
                            <p className="text-[#71717A] font-SuisseIntlLight font-medium text-xs">
                              {invoice.name}
                            </p>
                            <h1 className="font-SuisseIntlLight font-semibold text-base">
                              {invoice.date}
                            </h1>
                          </div>
                        </TableCell>
                        <TableCell className="text-right text-base text-[#09090B] font-semibold">
                          {invoice.totalTokens}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Card>
        </CardContent>
      </Card>
    );
};

export default Billing;