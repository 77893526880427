import React, { useEffect, useState } from 'react'
import RecordAudio from '../components/RecordAudio'
import UploadFile from '../components/UploadFile';
import { Tabs } from '../components/ui/Tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CardWrapper, MainCardWrapper } from '../components/ui/CardWrapper';
import { getuser } from '../utils/HelperFunctions';
import { FetchReportTemplates } from '../redux/ReportsApis';
import { languageList, allModelsLanguageList } from '@/utils/constants';
import { setPayingCustomer, setServiceName, setTrialEndDate, setWordCount, selectUserData, setUserData } from '../redux/UserSlice';

function AddReport({addingReport = false, toolInputs}) {
  // console.log('adding report', addingReport, 'generation', generation_name)
  const [customTemplate, setCustomTemplate] = useState(null)
  const [hidingTabs, setHidingtabs] = useState();
  console.log('hiding tabs', hidingTabs)
  const userToken = localStorage.getItem('user_session')
  const userId = localStorage.getItem('user_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userData = useSelector(selectUserData);
  let isDisabled = {loading: true, noWords: true};
  isDisabled.loading = userData.length === 0
  isDisabled.noWords = userData.words === 0;

  const inputs = toolInputs.map((input, index)=>{
    const name = input.type.split('_').reverse().join(' ');
    return {
      name: name.charAt(0).toUpperCase() + name.slice(1),
      active: index === 0,
      value: input.type
    }
  })
  const [tabs, setTabs] = useState([
    {
      name: 'Record audio',
      active: true,
      value: 'audio_record'
    },
    {
      name: 'Upload audio',
      active: false,
      value: 'audio_upload'
    }
  ])
  const [selectedTab, setSelectedTab] = useState(inputs[0].value)
  console.log('selected tab', selectedTab)

  useEffect(() => {
    dispatch(FetchReportTemplates())
  }, [dispatch])

  const handleTabChange = async (tabType) => {
    let updatedTabs = []

    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        setSelectedTab(tab.value)
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value
        })
      }
      else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value
        })
      }
    })
    setTabs(updatedTabs)

  };

  useEffect(() => {
    if (!userId) {
      navigate('/')
    }
  }, [userId, navigate])


  useEffect(() => {
    try {
      const userId = localStorage.getItem('user_id')
      if (!userId || userId === 'undefined') {
        return
      }
      const getUserData = async () => {
        const UserData = await getuser({ user_id: userId })
        if (UserData) {
          dispatch(setWordCount(UserData.words))
          dispatch(setServiceName(UserData.service_name))
          dispatch(setPayingCustomer(UserData.paying_customer))
          dispatch(setTrialEndDate(UserData.trial_end_date))
          dispatch(setUserData(UserData))
          setCustomTemplate(UserData.custom_template)
        }
      }
      getUserData()
    } catch (error) {
      console.log("User Data not found");
    }

  }, [userToken, dispatch])

  const getSortedList = (languages) => {
    languages.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      } else {
        return 0;
      }
    })
    return languages
  }
  
  const getToolInputs = () => {
    return (
      toolInputs?.map((input)=>{
        if(input.type === 'audio_record'){
          return (
            <div className={`${selectedTab === input.type ? '': 'hidden'}`} key={input.type}>
              <CardWrapper className='border-none p-0'>
                <RecordAudio setHidingtabs={setHidingtabs} isDisabled={isDisabled} languages={getSortedList(languageList)} customTemplate={customTemplate} allModelsLanguageList={allModelsLanguageList} />
              </CardWrapper>
            </div>
          )
        }
        if(input.type === 'audio_upload'){
          return (
            <div className={`${selectedTab === input.type ? '': 'hidden'}`} key={input.type}>
              <UploadFile setHidingtabs={setHidingtabs} isDisabled={isDisabled} languages={getSortedList(languageList)} customTemplate={customTemplate} allModelsLanguageList={allModelsLanguageList}/>
            </div>
          )
        }
        return null;
      })
    )
  }


  return (
        <MainCardWrapper addingReport>
          <div className='text-center '>
            {/*  <div className='flex md:flex-row flex-col md:justify-start justify-center items-center md:gap-4'>
              <div className='flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2'>
                <img width={'20px'} src={ClipboardImage} alt='clip board logo' />
              </div> */}
              {/* <div className="flex ">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2">
            <img width={'20px'} src={ClipboardImage} alt="clip board logo" />
          </div>
              <div className='md:text-left text-center'>
                <h1 className='font-medium text-[16px]'>Report creation</h1>
                <p className='text-[#505050] font-SuisseIntlLight mt-[4px] font-normal text-[14px]'>Record your voice or upload a pre-existing recording</p>
              </div>
            </div> */}
            <div className="flex justify-between flex-wrap items-center">
        <div className={`${hidingTabs === 0 ? 'hidden md:flex': 'hidden'}`}>
          <div className="text-left">
            <h1 className="font-medium text-[20px]">Create Report</h1>
            <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">Record your voice or upload a pre-existing recording</p>
          </div>
        </div>
     
      </div>
      <div className="flex flex-col sm:hidden">
          <h1 className="font-semibold font-SuisseIntlLight text-left text-[16px] mt-[10px]">Report creation</h1>
          <p className="text-[#505050] font-SuisseIntlLight text-left font-normal mt-[4px] text-[14px]">Record your voice or upload a pre-existing recording</p>
        </div>
     
            <div className={`mt-[28px] mb-[24px] ${hidingTabs === 0 ? 'block': 'hidden'}`}>
              <Tabs tabs={tabs} onChange={handleTabChange} />
            </div>
            {getToolInputs()}
          </div>
        </MainCardWrapper>
  )
}

export default AddReport