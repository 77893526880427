import React, { useState } from 'react'
import { GradientBorder } from './ui/GradientBorder'
import linkIcon from '../images/SVG/link.svg'
import { Card, CardContent, CardHeader } from './shadcn-ui/card'
import { Label } from './shadcn-ui/label'
import { Input } from './shadcn-ui/input'
import SliderStep from './SliderStep'
import { Button } from './shadcn-ui/button'

const CreateInvite = () => {
  const linkName = ["1 hour", "1 day", "1 week", "∞"];
  const [Linkvalue, setLinkValue] = useState(3);
  const [userNum, setUserNum] = useState(5);
  const UserNumber = ["1", "10","50", "100", "∞"];

  return (
    <Card className='w-full border-none p-0 shadow-none'>
      <CardContent className="p-3 flex justify-center items-center flex-col">
        <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={"24px"} src={linkIcon} alt="recording mic" />
          </div>
        </GradientBorder>
        <CardHeader className="font-semibold font-SuisseIntlLight md:text-[24px] pb-2 text-[20px] text-center">
          New Link
        </CardHeader>
        <CardContent className='w-full p-0 dark:shadow-none'>
            <Label className="font-semibold">Link Name</Label>
            <Input className='mt-2 mb-3' placeholder='Optional'/>
            <Label className="font-semibold">Link Duration</Label>
            <SliderStep labels={linkName} text='Expiry Date' max={linkName.length - 1} expiryText={Linkvalue === linkName.length - 1 ? "Never" : linkName[Linkvalue]} value={Linkvalue} setValue={setLinkValue}/>
            <Label className="font-semibold">Limit by number of users</Label>
            <SliderStep labels={UserNumber} text='Number of Users' max={UserNumber.length - 1} expiryText={userNum === UserNumber.length - 1  ? "Unlimited" : UserNumber[userNum]} value={userNum} setValue={setUserNum}/>  
          <Button className='w-full'>
            Create
          </Button>
        </CardContent>
      </CardContent>
    </Card>
  )
}

export default CreateInvite