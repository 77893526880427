import React, { useState } from "react";
import DarkLogo from "../images/logotype.png";
import { Link, useNavigate } from "react-router-dom";
import AddUserIcon from "../images/SVG/addUser.svg";
import {
  Card,
  CardContent,
  CardFooter,
  CardTitle,
} from "@/components/shadcn-ui/card";
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { GradientBorder } from "@/components/ui/GradientBorder";
import { createUser } from "@/utils/HelperFunctions";
import { getCurrentUser, signIn, confirmSignIn } from 'aws-amplify/auth';
import { toast } from "react-toastify";

const SignUp = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    inviteCode: "",
    orgName: "",
  });

  const navigate = useNavigate();
  const [activeField, setActiveField] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if ((name === "inviteCode" || name === "orgName") && value.trim() === "") {
      setActiveField(null);
    } else if (name === "inviteCode" || name === "orgName") {
      setActiveField(name);
    }
  };

  const handleSignUp = async () => {
    try {
      setLoading(true);
      if (formData.inviteCode === "" && formData.orgName === "") {
        toast.error("Please enter invite code or organisation name");
        return;
      }
      const response = await createUser(formData);
      if (response) {
        const { nextStep } = await signIn({ username: formData.email, password: formData.password });
        if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          await confirmSignIn({ challengeResponse: formData.password });
        }

        const { userId } = await getCurrentUser();
        localStorage.setItem("user_id", userId)
        localStorage.setItem("email", formData.email)
        localStorage.removeItem('session_expired');
        navigate("/")
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex gap-3 items-center fixed top-12">
        <img className='md:w-36 w-28 object-contain' src={DarkLogo} alt="logo not found" />
        <p className='text-black font-SuisseIntlLight font-medium text-[14px] md:text-[16px]'>Medical AI Suite</p>
      </div>
      <div className="flex items-center justify-center h-screen">
        <div className="w-full bg-[#FAFAFA] flex flex-col items-center font-SuisseIntl mt-8 text-3xl font-bold text-gray-400">
          <Card className="mt-14 w-[90vw] md:w-[440px] max-w-[440px] rounded-[20px]">
            <div className="flex justify-center mt-7 mb-4">
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img
                    width={"24px"}
                    height={"24px"}
                    src={AddUserIcon}
                    alt="Login circle not found"
                  />
                </div>
              </GradientBorder>
            </div>
            <CardTitle className="text-[20px] text-center w-full font-semibold">
              Create a new account
            </CardTitle>
            <CardContent>
              <form>
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name" className="font-semibold">Full Name</Label>
                    <Input
                      required
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Please enter your name"
                      autoComplete="off"
                      className="text-[#868C98] font-normal"
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="email" className="font-semibold">Email</Label>
                    <Input
                      required
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Please enter your email"
                      autoComplete="new-email"
                      className="text-[#868C98] font-normal"
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="password" className="font-semibold">Password</Label>
                    <Input
                      required
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Please enter your password"
                      type="password"
                      autoComplete="new-password"
                      className="text-[#868C98] font-normal"
                    />
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="inviteCode" className="font-semibold">Invite Code</Label>
                      <div className="relative">
                        <Input
                          id="inviteCode"
                          name="inviteCode"
                          value={formData.inviteCode}
                          onChange={handleChange}
                          className="peer ps-6 text-[#868C98] font-normal"
                          placeholder="12345678"
                          autoComplete="off"
                          type="text"
                          disabled={activeField === "orgName"}
                        />
                        <span className="text-[#868C98] font-light pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-sm text-muted-foreground peer-disabled:opacity-50">
                          #
                        </span>
                      </div>
                    </div>
                    <span className="text-xs mt-8 text-[#868C98] font-bold">OR</span>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="name" className="font-semibold">Create organisation</Label>
                      <Input
                        id="orgName"
                        name="orgName"
                        value={formData.orgName}
                        onChange={handleChange}
                        placeholder="Enter name"
                        className="text-[#868C98] font-normal"
                        disabled={activeField === "inviteCode"}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </CardContent>
            <CardFooter className="flex justify-center w-full flex-col">
              <Button variant="default" className="w-full" onClick={handleSignUp} disabled={loading}>
                Sign Up
              </Button>
              <p className="text-[14px] font-normal mt-4 text-[#09090B]">
                Already have an account?{" "}
                <Link to="/" className="text-black font-semibold underline">
                  Sign in
                </Link>
              </p>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SignUp;
