import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/shadcn-ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/shadcn-ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/shadcn-ui/dropdown-menu";
import { Button } from "@/components/shadcn-ui/button";
import { GradientBorder } from "@/components/ui/GradientBorder";
import { ArrowLeft, CopyIcon, Ellipsis, Trash2 } from "lucide-react";
import linkIcon from "@/images/SVG/link.svg";
import CreateInvite from "@/components/CreateInvite";


const InviteMembers = ({ onClose, members }) => {
    const inviations = [
      {
        inviteName: "Health Advisor code",
        propleJoined: "1 Joined",
        code: "#83e32loo",
      },
      {
        inviteName: "mpassist.ai/invite/#83e32loo",
        propleJoined: "Nobody Joined Yet",
        code: "#83e32loo",
      },
      {
        inviteName: "mpassist.ai/invite/#83e32loo",
        propleJoined: "Nobody Joined Yet",
        code: "#83e32loo",
      },
      {
        inviteName: "mpassist.ai/invite/#83e32loo",
        propleJoined: "Nobody Joined Yet",
        code: "#83e32loo",
      },
      {
        inviteName: "mpassist.ai/invite/#83e32loo",
        propleJoined: "Nobody Joined Yet",
        code: "#83e32loo",
      },
      {
        inviteName: "mpassist.ai/invite/#83e32loo",
        propleJoined: "Nobody Joined Yet",
        code: "#83e32loo",
      },
    ];
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0 flex-row flex justify-between items-center">
          <div className="flex items-center gap-2">
            <ArrowLeft
              className="text-[#71717A] cursor-pointer"
              onClick={onClose}
            />
            <CardTitle>Invite Members</CardTitle>
          </div>
          <div className={`${members.length > 0 ? "flex" : "hidden"}`}>
            <Dialog>
              <DialogTrigger>
                <Button>Create Link</Button>
              </DialogTrigger>
              <DialogContent className='rounded-2xl shadow-none max-w-[419px]'>
                <CreateInvite/>
              </DialogContent>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent className="md:px-6 px-0 p-0">
          {members.length > 0 ? (
            <Card className="rounded-2xl">
              <CardContent className="px-0 p-0">
                <Table>
                  <TableHeader>
                    <TableRow className="hover:bg-transparent">
                      <TableCell className="font-semibold text-[#71717A] pl-16">
                        Link Name
                      </TableCell>
                      <TableCell className="font-semibold text-[#71717A]">
                        Members Joined
                      </TableCell>
                      <TableCell className="font-semibold text-[#71717A]">
                        Invite Code
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {inviations.map((invite, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="flex gap-3 items-center">
                            <div className="p-2 bg-[#F4F4F5] rounded-full">
                              <img
                                src={linkIcon}
                                alt=""
                                className="w-4 h-4 object-contain max-w-none"
                              />
                            </div>
                            <span className="font-SuisseIntlLight font-semibold text-black">
                              {invite.inviteName.length > 25
                                ? `${invite.inviteName.slice(0, 25)}...`
                                : invite.inviteName}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className="text-[#71717a] font-semibold">
                          {invite.propleJoined}
                        </TableCell>
                        <TableCell className="text-[#0055FF] font-semibold">
                          <div className="flex items-center justify-between">
                            <span>{invite.code}</span>
                            <div>
                              <DropdownMenu className="-left-11">
                                <DropdownMenuTrigger>
                                  <Button
                                    variant="ghost"
                                    className="px-2 h-fit py-2"
                                  >
                                    <Ellipsis
                                      className="text-[#71717A]"
                                      width={15}
                                      height={15}
                                    />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="-left-11">
                                  <DropdownMenuItem className="border-b border-[#E4E4E7]">
                                    <CopyIcon width={16} height={16} />
                                    <span>Copy Link</span>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem className="text-red-500">
                                    <Trash2 width={16} height={16} />
                                    <span>Revoke Link</span>
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          ) : (
            <Card className="rounded-2xl">
              <CardContent className="p-3 h-96 flex justify-center items-center flex-col">
                <GradientBorder>
                  <div className="shadow-sm bg-white rounded-full px-4 py-4">
                    <img width={"24px"} src={linkIcon} alt="recording mic" />
                  </div>
                </GradientBorder>
                <CardHeader className="font-semibold font-SuisseIntlLight md:text-[24px] pb-2 text-[20px] text-center">
                  You don't have any invite Links
                </CardHeader>
                <CardDescription className="mb-[14px] text-[#505050] md:text-[16px] text-[14px] font-SuisseIntlLight font-normal">
                  Create the first one
                </CardDescription>
                <Dialog>
                  <DialogTrigger>
                    <Button>Create Link</Button>
                  </DialogTrigger>
                  <DialogContent className="rounded-2xl shadow-none max-w-[420px]">
                    <CreateInvite />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          )}
        </CardContent>
      </Card>
    );
};

export default InviteMembers;

