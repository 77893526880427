import React from "react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/shadcn-ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Link } from "react-router-dom";
import { timeZones, allModelsLanguageList } from "@/utils/constants";

const General = () => {
    return (
      <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
        <CardHeader className="pt-0 md:px-6 px-0">
          <CardTitle>General</CardTitle>
          <CardDescription className="font-normal mt-2 text-[14px] text-[#71717a]">
            Manage Your Profile Settings
          </CardDescription>
        </CardHeader>
        <CardContent className="md:px-6 px-0">
          <Card className="rounded-2xl">
            <CardContent className="p-3">
              <form action="" className="flex flex-col gap-3 flex-wrap">
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <Label className="font-SuisseIntlLight font-semibold">
                      Email
                    </Label>
                    <Link className="font-SuisseIntlLight font-semibold text-[14px] text-[#71717A] underline">
                      Change
                    </Link>
                  </div>
                  <Input
                    className="font-semibold placeholder:font-SuisseIntlLight placeholder:font-semibold"
                    placeholder="george@mail.com"
                    type="email"
                  />
                </div>
                <div className="flex gap-3 items-center justify-between">
                  <div className="w-full flex flex-col gap-2">
                    <Label className="font-semibold font-SuisseIntlLight">
                      Language
                    </Label>
                    <Select>
                      <SelectTrigger>
                        <SelectValue
                          className="font-semibold placeholder:font-semibold"
                          placeholder="Select a Language"
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {allModelsLanguageList.map((lang) => (
                            <SelectItem value={lang.value}>
                              {lang.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <Label className="font-semibold">Time Zone</Label>
                    <Select>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a time zone" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {timeZones.map((zone, index) => (
                            <SelectItem value={zone} key={index}>
                              {zone}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    );
  };

export default General;