import React, { useEffect, useRef, useState, useCallback } from 'react'
import RecordForm from './RecordForm';
import CheckCircle from '../images/check-circle.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFileUploading, setSelectedReport } from '../redux/ReportsSlice'
import Button from './ui/Button'
import Microphone from '../images/microphone.png'
import { GradientBorder } from './ui/GradientBorder'
import Loader from './Loader'
import { createRecord, handleUpdateStatus, handleUpdateError, getSignedUrl, uploadToS3 } from '../utils/HelperFunctions'
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import Recorder from './Recorder'
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { Tooltip } from 'react-tooltip';
import { useCurrentTool } from '@/utils/ToolHelpers';
import succeedIcon from '../images/SVG/succeedIcon.svg'

function RecordAudio({ isDisabled, customTemplate, languages, allModelsLanguageList, setHidingtabs }) {

  const { currentToolFields, generation_name } = useCurrentTool();

  const [fieldValues, setFieldValues] = useState(() => {
    const initialValues = currentToolFields.reduce((acc, field) => {
      acc[field.name] = localStorage.getItem(field.name) || ''; 
      return acc;
    }, {});
    return initialValues;
  });

  const payload = useSelector((state) => state?.reportsSlice?.latestPayload)
  const [recordingStatus, setRecordingStatus] = useState('start')
  const [recordingTime, setRecordingTime] = useState(0)
  const [generateReport, setGenerateReport] = useState('')
  const [tempReport, setTempReport] = useState({})
  const [uploading, setUploading] = useState(true)
  const [, setPauseRecording] = useState(false)
  const [recordedBlob, setRecordedBlob] = useState([])
  const reportIdRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleResetState = useCallback(() => {
    setFieldValues(
      currentToolFields.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {})
    );

    setRecordingStatus('start');
    setRecordingTime(0);
    setGenerateReport('');
    setTempReport({});
    setUploading(true);
    setPauseRecording(false);
    reportIdRef.current = null;
  }, [currentToolFields]);
  
  useEffect(()=>(
    setHidingtabs(recordingTime)
  ),[recordingTime, setHidingtabs])

  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const loadFfmpeg = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }) => {
        console.log(message);
    });
    await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    setFfmpegLoaded(true);
  }

  const convertWebmToMp3 = async (webmBlob) => {
    
    if (!ffmpegLoaded) {
      await loadFfmpeg();
    }

    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile('input.webm', await fetchFile(webmBlob));
    await ffmpeg.exec(['-i', 'input.webm', 'output.mp3']);
    const data = await ffmpeg.readFile('output.mp3');

    return new Blob([data.buffer], {type: 'audio/mp3'});
    }


  // const formatTime = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);    const remainingSeconds = seconds % 60;
  //   return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  // };

  useEffect(() => {
    if (!!payload) {
      if (reportIdRef.current && payload.id === reportIdRef?.current) {
        if (payload.report_fetched) {
          setTempReport(payload)
        } else if (payload.status === "completed") {
          handleResetState()
        }
      }
    }
  }, [payload, handleResetState])

  
  const handleGenerateReport = async ({ toolFieldsData, selectedFile, recordedAudioBlob, reportLanguage, audioLanguage, comment, template, uses_custom_template }) => {
    let documentId = ''
    try {
        dispatch(setFileUploading(true))
        const userId = localStorage.getItem('user_id')

        const allowedAudioTypes = [
            'audio/flac',
            'audio/mp4',
            'audio/mpeg',
            'audio/ogg',
            'audio/wav',
            'audio/webm'
        ];

        if (selectedFile) {
            if (selectedFile?.size / (1024 * 1024) > 90.0) {
                toast.error("The audio file is too large. The maximum size is 90 MB.");
                return;
            }
            if (!allowedAudioTypes?.includes(selectedFile?.type)) {
                toast.error("Please upload an audio file.");
                return;
            }
        }
        else {
            if (recordedAudioBlob?.size / (1024 * 1024) > 90.0) {
                toast.error("The audio file is too large. The maximum size is 90 MB.");
                return;
            }
        }
        let mergedBlob;
        if(recordedBlob){
          mergedBlob = recordedBlob;
        }

        if (mergedBlob?.type === 'audio/webm'){
          console.log('Converting webm to mp3')
          mergedBlob = await convertWebmToMp3(mergedBlob)
        }
        
        toolFieldsData.forEach((field) => {
          if(field.name !== 'patient_name')
            localStorage.setItem(field.name, field.value)
        })
        localStorage.setItem('audio_language', audioLanguage)
        localStorage.setItem('report_language', reportLanguage)
        localStorage.setItem('uses_custom_template',uses_custom_template)

        documentId = await createRecord({ userId: userId, toolFieldsData, reportLanguage: reportLanguage, uses_custom_template, audioLanguage, comment, template, meta_type: mergedBlob?.type });
        // documentId = await createRecord({ userId, toolId, toolFieldsData, reportLanguage, uses_custom_template, audioLanguage, comment, template, meta_type: mergedBlob?.type });
        if (!documentId) {
            toast.error('Failed to create record. Please try again!')
            return
        }
        setUploading(true)
        const signedUrl = await getSignedUrl({ id: documentId, name: documentId, type: "upload" })
        const uploadStatus = await uploadToS3({ id: documentId, signedUrl: signedUrl, file: mergedBlob })
        if (!uploadStatus) {
            await handleUpdateStatus({ status: 'processing', documentId: documentId })
            return toast.error("Error uploading the file. Please try again.");
        }
        const recordUpdated = await handleUpdateStatus({ status: 'processing', documentId: documentId })
        console.log("Record status update ", recordUpdated);
        if (!recordUpdated) {
            return
        }
        const formData = new FormData()
        formData.append('documentId', documentId)
        formData.append('audioLanguage', audioLanguage)
        formData.append('reportLanguage', reportLanguage)

        const dataToSend = {
            "id": documentId,
            "user_id": userId
        }
      const accessToken = await fetchAuthSessionWithLogout();
      if (!accessToken) {
        return
      }
        const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/report`, {
            method: "POST",
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
        },
        )
        const responesData = await response.json()
        if (responesData.statusCode !== 200) {
            if (response.status === 401) {
                handleUpdateError({ id: documentId, error: "Session expired." })
                localStorage.removeItem('user_session');
                navigate('/')
                toast.error('Your session has expired. Please login and try again.')
                return
            } else {
                handleResetState()
                handleUpdateError({ id: documentId, error: JSON.stringify(responesData) })
                toast.error(`The ${generation_name.toLowerCase()} could not be created. Please try again.`)
                return
            }
        }
        reportIdRef.current = documentId
        setUploading(false)
        dispatch(setFileUploading(false))
    } catch (error) {
        console.log("API error ", error);
        if (JSON.stringify(error) === '{}') {
            handleUpdateError({ id: documentId, error: 'Failed to make request to backend server' })
        }
        else {
            handleUpdateError({ id: documentId, error: JSON.stringify(error) })
        }
        handleResetState()

        toast.error(`The ${generation_name.toLowerCase()} could not be created. Please try again.`)
    }
  }

  const genertae=()=>{
    setRecordingStatus("start");
  }

  const handleRecord = async (e) => {
    e.preventDefault();

    try {
      const permission = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (permission) {
        setRecordingStatus('recording');
      } else {
        toast.error('Microphone permission denied.');
        handleResetState()
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const handleGoToReport = () => {
    navigate('/report')
  }

  // useEffect(() => {
  //   if (tempReport?.report?.length > 0 && reportIdRef.current !== null) {

  //     setGenerateReport('audio-report-generated')
  //     let newReport = {...tempReport}
  //     newReport.report = newReport.report?.replace(/placeholder_name/g, name);
  //     newReport.report = newReport.report?.replace(/placeholder_doctor/g, doctor);
  //     newReport.report = newReport.report?.replace(/placeholder_clinic/g, clinic);
  //     dispatch(setSelectedReport(newReport))
  //   }


  // }, [tempReport,dispatch, name, clinic, doctor])

  useEffect(() => {
    if (tempReport?.report?.length > 0 && reportIdRef.current !== null) {
      setGenerateReport('audio-report-generated');

      let newReport = { ...tempReport };

      currentToolFields.forEach((field) => {
        const placeholder = `placeholder_${field.name}`;
        const value = fieldValues[field.name] || '';
        newReport.report = newReport.report?.replace(new RegExp(placeholder, 'g'), value);
      });

      dispatch(setSelectedReport(newReport));
    }
  }, [tempReport, dispatch, currentToolFields, fieldValues]);


  const handleAudio = (blob) => {
    setRecordedBlob(blob)
    setRecordingStatus('stop')
  }

  const handlePauseRecording = (isRecordingPaused) => {
    setPauseRecording(isRecordingPaused)
  }

  const disabled = isDisabled.loading || isDisabled.noWords;
  console.log(recordingTime, 'set recording')

  return (
    <>
      {
        recordingStatus === 'start' && (
          <>
            {isDisabled.noWords && <Tooltip id="no-words" place="bottom"/>}
            <div className='flex flex-col justify-center items-center text-center w-full p-5 rounded-xl border border-[#E4E4E7]' >
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} src={Microphone} alt='recording mic ' />
                </div>
              </GradientBorder>
              <h1 className='font-medium mt-[16px] text-[24px]'>Record your voice</h1>
              <p className='mt-[4px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>Record your speech for us to transcribe and prepare a {generation_name.toLowerCase()}</p>
              <div className='mt-[16px]' onClick={!disabled ? handleRecord : undefined} data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'>
                <Button className={`${disabled && 'cursor-not-allowed opacity-80'}`}>
                  Start recording
                </Button>
              </div>
            </div>
          </>
        )
      }
      {
        recordingStatus === 'recording' && (
          <>
            <div className='flex w-full flex-col justify-center items-center'>
              <div className='flex w-full flex-col justify-center items-center p-5 rounded-xl border border-[#E4E4E7]'>
                <Recorder handleAudio={handleAudio} autoStart={true} handlePauseRecording={handlePauseRecording} setRecordingTime={setRecordingTime} />
              </div>
            </div>
          </>
        )
      }
      {
        recordingStatus === 'stop' && generateReport ===''&&  (
          <div className='flex flex-col justify-center items-center w-full'>
            <GradientBorder >
              <div className="shadow-sm bg-white rounded-full p-3">
                <img className='max-w-none' src={succeedIcon} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-bold mt-[16px] font-SuisseIntlLight text-[24px]'>Speech Recorded</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] mb-[12px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>Fill data below and generate report.</p>
            <RecordForm GenerateReport={setGenerateReport} HandleResetState={handleResetState} UploadNewRecording={genertae} customTemplates={customTemplate} language={languages} allModelsLanguageList={allModelsLanguageList} handleGenerateReport={handleGenerateReport} recordedAudioBlob />
            
          </div>
        )
      }
      {
        generateReport === 'generating' && (
          <div className='flex flex-col justify-center items-center'>
            <GradientBorder>
              <div className="shadow-sm bg-white rounded-full px-4 py-4">
                <Loader />
              </div>
            </GradientBorder>
            {
              uploading ? (
                <>
                  <h1 className='font-medium mt-[16px] text-[24px]'>Uploading...</h1>
                  <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-medium text-[16px]'>Please wait a few seconds.</p>
                </>
              ) : (
                <>
                    <h1 className='font-medium mt-[16px] text-[24px]'>Generating...</h1>
                    <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-normal text-[16px]'>Please wait a few minutes.</p>
                </>
              )
            }
            <div>
              {
                uploading ? (
                  <Button variant='disabled' className='mt-[16px] cursor-default hidden' onClick={()=>{}}>
                    Add {generation_name.toLowerCase()}
                 </Button>
                ) : (
                  <Button variant='light' className='mt-[16px]' onClick={handleResetState}>
                    Add {generation_name.toLowerCase()}
                  </Button>
                )
              }
            </div>
         
          </div>
        )
      }
      {
        generateReport === 'audio-report-generated' && (
          <div className='flex flex-col justify-center items-center'>
            <GradientBorder variant={2}>
              <div className="shadow-sm bg-white rounded-full px-4 py-4">
                <img width={'24px'} src={CheckCircle} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-medium mt-[16px] text-[24px]'>The {generation_name.toLowerCase()} has been generated</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] text-[16px] text-[#505050] font-SuisseIntlLight font-normal'>You can review it by clicking the button below</p>
            <div>
              <Button onClick={handleGoToReport} className=' mt-[16px] ' variant='light'>
              Go to {generation_name.toLowerCase()}
              </Button>
            </div>
          </div>
        )
      }
    </>
  )
}

export default RecordAudio
